<template>
  <div class="main">
    <div class="header">
      <div class="logo">
        <img class="logo__image" src="@/assets/img/logo_red.svg" alt="Emporio Armani"/>
      </div>
    </div>
    <div class="ready">
      <div class="ready__polaroid">
        <img class="ready__image" :src="myDesign" alt="Result" />
      </div>
      <div class="ready__download">
        <a :href="myDesign" download="polaroid.jpg" class="button">
          <span>
            <img src="@/assets/img/icn_download.svg" alt="download icon"/>
          </span>
          <span>{{ $t('DOWNLOAD.DOWNLOAD_NOW') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    session: {
      type: String,
      default: "",
    },
    hash: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myDesign: `${process.env.VUE_APP_API_URL}/api/download-session-file/${this.session}/chalkboard.jpg/${this.hash}`//`${process.env.VUE_APP_API_URL}/api/download-session-file/${this.session}/${this.hash}?appId=chalkboard&fileid=polaroid.jpg`,
    };
  },
};
</script>
<style lang="scss" scoped>
.main {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  display: flex;
}
.header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid;
  flex-grow: 1;
}
.logo {
  width: 100%;
  padding: 0 10px;
  @media screen and (min-width: 500px){
    padding: 0 5px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  &__image {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    display: block;
  }
}

.ready {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1rem;
  overflow: hidden;
  overflow-y: auto;

  &__polaroid {
    margin: 0 auto;
    width: 100vw;
    height: 100vw;
    padding: .5rem;
    
    @media screen and (min-width: 500px){
      max-height: 500px;
      max-width: 500px;
      padding: 2rem;
    }
  }

  &__image {
    margin: 0 auto;
    display: block;
    transform: rotate(-8.9deg);
  }

  &__download {
    padding-top: 4vh;
    text-align: center;
  }
}

.button {
  //   margin: 0 0.572vw;
  min-width: 5.98vw;
  //   height: 4.25vh;
    font-family: "Jost_Medium";
    font-size: 1rem;
  //   font-size: 2vh;
  text-transform: uppercase;
  background-color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.052vw solid black;
  color: white;
  padding: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 275px;
  margin: 0 auto;

  img {
    height: 1.5rem; 
    margin-right: 1rem;
  }
}
</style>
