import { render, staticRenderFns } from "./Download.vue?vue&type=template&id=c4697218&scoped=true"
import script from "./Download.vue?vue&type=script&lang=js"
export * from "./Download.vue?vue&type=script&lang=js"
import style0 from "./Download.vue?vue&type=style&index=0&id=c4697218&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4697218",
  null
  
)

export default component.exports